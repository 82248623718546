import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";
import { Link } from "react-router-dom";

class ProfileParent extends Component {

  constructor(props) {
    super(props);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onChangeChild = this.onChangeChild.bind(this);
    this.onClickAddChild = this.onClickAddChild.bind(this);
    this.onClickRemoveChild = this.onClickRemoveChild.bind(this);
    this.onChangeGrade = this.onChangeGrade.bind(this);
    this.formRef = React.createRef();

    this.state = {
      className: "loading",
      email: "",
      role: "",
      firstName: "",
      lastName: "",
      phone: "",
      location: "",
      locations: [],
      children: [{
        "id": "",
        "name": "",
        "deleted": false,
        "gradeLevel": "",
      }],
      gradeLevels: [
        "K",
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th"
      ],
      profileUpdateNeeded: false,
    };

    var token = window.sessionStorage.getItem("token");
    var data = { token: token };
    AccountDataService.getProfile(data)
      .then(response => {
        const className = response.data.firstName == null
          || response.data.lastName == null
          || response.data.phone == null
          || response.data.location == null
          || response.data.profileUpdateNeeded
          ? "edit" : "view";
        this.setState({
          className: className,
          email: response.data.email,
          role: response.data.role,
          firstName: this.nullCheck(response.data.firstName),
          lastName: this.nullCheck(response.data.lastName),
          phone: this.nullCheck(response.data.phone),
          location: "" + this.nullCheck(response.data.location),
          locations: response.data.locations,
          children: this.nullCheck(response.data.children),
          profileUpdateNeeded: response.data.profileUpdateNeeded,
        });
      }).catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
      });
  }

  nullCheck(d) {
    return d == null ? "" : d;
  }

  nullCheckCheckbox(d) {
    return d == null ? false : d;
  }

  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  onChangeLastName(e) {
    this.setState({ lastName: e.target.value });
  }

  onChangePhone(e) {
    this.setState({ phone: e.target.value });
  }

  onChangeLocation(e) {
    this.setState({ location: e.target.value });
  }

  saveProfile() {
    const node = this.formRef.current;
    node.classList.add('was-validated');
    if (!node.checkValidity()) {
      node.reportValidity();
      return;
    }

    const newChildren = this.state.children?.filter((child) =>
      (child.id !== "" || child.name !== "") && (child.gradeLevel !== "")
    );

    this.setState({
      children: newChildren,
    })

    var data = {
      token: window.sessionStorage.getItem("token"),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      location: this.state.location === "" ? null : Number(this.state.location),
    }
    if (this.state.children) {
      data.children = this.state.children;
    }

    AccountDataService.updateProfile(data)
      .then(response => {
        this.setState({ className: "view" });
        var snackbar = document.getElementById("snackbar");
        snackbar.className = "show";

        // Disable till refresh
        const buttonElements = document.querySelectorAll("button");
        buttonElements.forEach(button => {
          button.disabled = true;
        });

        setTimeout(function () {
          snackbar.className = snackbar.className.replace("show", "");
          window.location.reload(); // Workaround for duplicating children glitch
        }, 1000);
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
      })
  }

  onClickEdit(e) {
    const node = this.formRef.current;
    node.classList.remove('was-validated');
    this.setState({ className: "edit" });
  }

  onChangeChild(e) {
    const node = this.formRef.current;
    node.classList.remove('was-validated');
    const { value, id } = e.target;
    const newChildren = [...this.state.children];
    newChildren[id].name = value;
    this.setState({
      children: newChildren,
    })
  };

  onClickAddChild(e) {
    const newChildren = [...this.state.children, {
      "name": "",
      "deleted": false,
      "gradeLevel": ""
    }];
    this.setState({
      children: newChildren,
    })
  }

  onClickRemoveChild(e) {
    const { id } = e.target;
    const newChildren = [...this.state.children];
    if (newChildren[id].id) {
      newChildren[id].deleted = true;
    } else {
      newChildren.splice(id, 1);
    }
    this.setState({
      children: newChildren,
    })
  }

  onChangeGrade(e) {
    const { value, id } = e.target;
    const newChildren = [...this.state.children];
    newChildren[id].gradeLevel = value;
    this.setState({ children: newChildren });
  }

  render() {
    return (
      <div className="fancy-box">
        <div className={this.state.className}>
          <h1 className="mb-3">Profile {this.state.role && (<span>[{this.state.role}]</span>)}</h1>
          <div className="loading mb-3">
            <p>Loading</p>
          </div>
          <div className="viewedit registration-form">
            {this.state.profileUpdateNeeded && (
              <div className="small badge rounded-pill text-bg-warning mb-3">Please confirm that &quot;Grade Level&quot; and all other details correct and click Save.</div>
            )}
            <form className="row needs-validation" noValidate ref={this.formRef}>
              <div className="col-12 mb-3">
                <button id="saveButton" type="button" className="btn btn-secondary view" onClick={this.onClickEdit}>Edit Profile</button>
                <button id="resetPasswordButton" type="button" className="btn green-button edit" onClick={this.saveProfile}>Save Profile</button>
                <Link to={"/reset-password"}><button className="btn btn-light">Reset Password</button></Link>
              </div>

              {/* Information */}
              <div className="row">

                {/* Left column */}
                <div className="col-12 col-lg-6">

                  {/* Email */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="text" className="form-control" id="email" disabled required value={this.state.email} name="email" aria-describedby="emailHelp" />
                  </div>

                  {/* First name */}
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">First name</label>
                    <input type="text" className="form-control" id="firstName" required value={this.state.firstName} onChange={this.onChangeFirstName} name="firstName" aria-describedby="firstNameHelp" />
                    <div className="invalid-feedback">Please provide your first name</div>
                  </div>

                  {/* Last name */}
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Last name</label>
                    <input type="text" className="form-control" id="lastName" required value={this.state.lastName} onChange={this.onChangeLastName} name="lastName" />
                    <div className="invalid-feedback">Please provide your last name</div>
                  </div>

                  {/* Phone */}
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input type="text" className="form-control" id="phone" required value={this.state.phone} onChange={this.onChangePhone} name="phone" />
                    <div className="invalid-feedback">Please provide your phone number</div>
                  </div>
                </div>

                {/* Right column */}
                <div className="col-12 col-lg-6">

                  {/* Location */}
                  <div className="mb-3">
                    <label className="form-label">Branch</label> <span className="form-text">(<a href="https://www.intellichoice.org/branches/" rel="noreferrer" target="_blank">See locations and schedules</a>)</span>
                    <div className="mb-3">
                      <select className="mb-0 form-select" id="location" required value={this.state.location} onChange={this.onChangeLocation} name="location">
                        <option key="" value="">--- Select --</option>
                        {this.state.locations.map(l => (<option key={l.id} value={l.id}>{l.name}</option>))}
                      </select>
                    </div>
                  </div>

                  {/* Children */}
                  <div className="mb-3">
                    <label className="form-label">Children</label>
                    {this.state.children?.map((child, i) => {
                      if (child.deleted === false) {
                        return (
                          <div className="child-container">
                            <input type="text" className="mb-3 me-3 form-control child-name" id={i} value={child.name} placeholder="Name" onChange={this.onChangeChild} name={child.name} required />
                            <select className="mb-3 me-3 form-select child-grade" id={i} value={child.gradeLevel} onChange={this.onChangeGrade} name="gradeLevel" required>
                              <option key="" value="">Grade</option>
                              {this.state.gradeLevels.map((gradeLevel, index) => (<option key={index} value={index}>{gradeLevel}</option>))}
                            </select>
                            <button type="button" className="mb-3 btn btn-danger small-button edit" id={i} title="Remove child" onClick={this.onClickRemoveChild}>-</button>
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                    <div className="mb-3">
                      <button type="button" className="btn btn-light add-button small-button edit" title="Add child" onClick={this.onClickAddChild}>+</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div id="snackbar">Saving profile...</div>
      </div>
    );
  }
}

export default withRouter(ProfileParent);