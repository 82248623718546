import React from "react";
import { Link } from 'react-router-dom';

class NavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {to: props.to, text: props.text}
  }

  render() {
    return (
      <li className="nav-item">
        <Link to={this.state.to} className="nav-link">
          {this.state.text}
        </Link>
      </li>
    );
  }
}

export default NavItem;