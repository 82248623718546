import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";
import { Link } from "react-router-dom";
import "../App.css"

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.formRef = React.createRef();
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      passwordsDontMatch: false,
    };
  }

  onPasswordChange = (e) => {
    const node = this.formRef.current;
    node.classList.remove('was-validated');
    const { name, value } = e.target;
    this.setState({
      errorMessage: "",
    });
    this.setState({ [name]: value }, this.validatePasswordMatch);
  };

  validatePasswordMatch = () => {
    const { newPassword, confirmNewPassword } = this.state;
    const passwordsDontMatch = (newPassword !== confirmNewPassword) && newPassword && confirmNewPassword;
    this.setState({ passwordsDontMatch });
  };

  resetPassword() {
    if (this.state.passwordsDontMatch) {
      return;
    }
    const node = this.formRef.current;
    node.classList.add('was-validated');
    if (!node.checkValidity()) {
      return;
    }
    var data = {
      token: window.sessionStorage.getItem("token"),
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword
    };
    AccountDataService.resetPassword(data)
      .then(response => {
        var snackbar = document.getElementById("snackbar");
        snackbar.className = "show";
        setTimeout(function () { snackbar.className = snackbar.className.replace("show", ""); }, 3000);
        setTimeout(() => {
          this.props.router.navigate("/profile");
        }, 1000);
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log("Error message:");
        console.log(e.response.data.message);
        this.setState({ errorMessage: e.response.data.message });
        node.classList.remove('was-validated');
      });
  }

  render() {
    return (
      <>
        <div className="center-center fancy-box">
          <h1 className="mb-3">Reset Password</h1>
          <form className="needs-validation" noValidate ref={this.formRef}>
            <div className="mb-3">
              <input type="password" className="form-control" id="oldPassword" placeholder="Old Password" required minLength="8" value={this.state.oldPassword} onChange={this.onPasswordChange} name="oldPassword" />
              <div className="invalid-feedback">Please provide a password with 8 or more characters</div>
              {this.state.errorMessage === "Incorrect password" && (<span className="text-danger">Incorrect password</span>)}
            </div>
            <div className="mb-3">
              <input type="password" className="form-control" id="newPassword" placeholder="New Password" required minLength="8" value={this.state.newPassword} onChange={this.onPasswordChange} name="newPassword" />
              <div className="invalid-feedback">Please provide a password with 8 or more characters</div>
            </div>
            <div className="mb-3">
              <input type="password" className="form-control" id="confirmNewPassword" placeholder="Confirm New Password" required minLength="8" value={this.state.confirmNewPassword} onChange={this.onPasswordChange} name="confirmNewPassword" />
              <div className="invalid-feedback">Please provide a password with 8 or more characters</div>
              {this.state.passwordsDontMatch && <span className="text-danger">Password confirmation doesn't match</span>}
            </div>
            <button type="button" className="btn green-button" onClick={this.resetPassword}>Reset</button>
            <Link to={"/profile"}><button className="btn btn-light">Back</button></Link>
          </form>
        </div>
        <div id="snackbar">Password updated!</div>
      </>
    );
  }
}

export default withRouter(ResetPassword);