import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.sendCode = this.sendCode.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.formRef = React.createRef();

    this.state = {
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangeCode(e) {
    this.setState({
      code: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  verifyEmail() {
    const node = this.formRef.current;
    node.classList.add('was-validated')
    if (!node.checkValidity()) {
      return;
    }
    var data = {
      email: this.state.email,
      code: parseInt(this.state.code, 10)
    };
    AccountDataService.verifyEmail(data)
      .then(response => {
        console.log(response.data);
        this.props.router.navigate("/login");
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
        this.setState({ errorMessage: e.response.data.message })
      });
  }

  sendCode() {
    const node = this.formRef.current;
    node.classList.add('was-validated')
    if (!node.checkValidity()) {
      return;
    }
    node.classList.remove('was-validated')
    AccountDataService.sendCode({ email: this.state.email })
      .then(response => {
        this.setState({emailSent: true});
      })
      .catch(e => {
        this.setState({errorMessage: e.response.data.message})
      });
  }

  resetPassword() {
    const node = this.formRef.current;
    node.classList.add('was-validated')
    if (!node.checkValidity()) {
      return;
    }
    node.classList.remove('was-validated')
    AccountDataService.forgotPassword({ email: this.state.email, code: this.state.code, password: this.state.password })
      .then(response => {
        this.props.router.navigate("/login");
      })
      .catch(e => {
        this.setState({errorMessage: e.response.data.message})
      });
  }

  render() {
    return (
      <div className="center-center fancy-box">
        <h1 className="mb-3">Forgot Password?</h1>
        <form className="needs-validation" noValidate ref={this.formRef}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" required disabled={this.state.emailSent ? 1 : 0} onChange={this.onChangeEmail} name="email" aria-describedby="emailHelp" />
            <div className="invalid-feedback">Enter your email</div>
          </div>
          {this.state.emailSent && (
            <div>
              <div className="mb-3">
                <label htmlFor="code" className="form-label">Code</label>
                <input id="code" name="code" type="text" className="form-control" required value={this.state.code} onChange={this.onChangeCode} />
                <div className="invalid-feedback">Enter code from the email sent to you</div>
              </div>
              <div className="mb-3">
                <label htmlFor="code" className="form-label">New password</label>
                <input id="password" name="password" type="password" className="form-control" required minLength="8" value={this.state.password} onChange={this.onChangePassword} />
                <div className="invalid-feedback">Enter your new password consisting of 8 or more characters</div>
              </div>
            </div>
          )}
          {!this.state.emailSent && (<button type="button" className="btn btn-primary green-button" onClick={this.sendCode}>Send Password Reset Code</button>)}
          {this.state.emailSent && (<button type="button" className="btn btn-primary green-button" onClick={this.resetPassword}>Reset Password</button>)}
          {this.state.errorMessage && (<span className="text-danger">{this.state.errorMessage}</span>)}
        </form>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
