import http from "../http-common";

class AttendanceService {
  listDates() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/dates", {params: {token: token}});
  }

  listMembers(calendarId) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/members/" + calendarId, {params: {token: token}});
  }

  listSignups(calendarId) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/signups/" + calendarId, {params: {token: token}});
  }
  saveAttendance(attendanceChanges) {
    const token = window.sessionStorage.getItem("token");
    return http.post("/attendances", {attendanceChanges: attendanceChanges}, {params: {token: token}});
  }
}

export default new AttendanceService();
