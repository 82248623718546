import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.formRef = React.createRef();

    this.state = {
      email: "",
      code: "",
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.setState({ email: window.sessionStorage.getItem("email") });
  }

  onChangeCode(e) {
    this.setState({
      code: e.target.value,
      errorMessage: "",
    });
  }

  verifyEmail() {
    const node = this.formRef.current;
    node.classList.add('was-validated')
    if (!node.checkValidity()) {
      return;
    }
    var data = {
      email: this.state.email,
      code: parseInt(this.state.code, 10)
    };
    AccountDataService.verifyEmail(data)
      .then(response => {
        console.log(response.data);
        this.props.router.navigate("/login");
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
        this.setState({ errorMessage: e.response.data.message })
      });
  }

  resendCode() {
    // TODO disable resend button
    AccountDataService.resendCode({ email: this.state.email })
      .then(response => {
        // TODO display success
        // start timer to enable resend button
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
      });
  }

  render() {
    return (
      <div className="center-center fancy-box">
        <h1 className="mb-3">Verify Email</h1>
        <form className="needs-validation" noValidate ref={this.formRef}>
          <div className="mb-3">
            <div className="mb-3">
              <div>Don't leave this page!</div>
              <div>A verification code has been sent to your email ({this.state.email}). Please enter it here to complete account setup.</div>
            </div>
            <input type="text" className="form-control" id="code" placeholder="Verification Code" required value={this.state.code} onChange={this.onChangeCode} name="code" aria-describedby="codeHelp" />
            <div className="invalid-feedback">Please provide the validation code</div>
          </div>
          <button type="button" className="btn btn-primary green-button" onClick={this.verifyEmail}>Submit</button>
          <button type="button" className="btn btn-secondary" onClick={this.resendCode}>Resend Verification Code</button>
          {this.state.errorMessage && (<span className="text-danger">Invalid code</span>)}
        </form>
      </div>
    );
  }
}

export default withRouter(VerifyEmail);
