import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";
import { Link } from "react-router-dom";

class ProfileTutor extends Component {

  constructor(props) {
    super(props);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeDobYear = this.onChangeDobYear.bind(this);
    this.onChangeDobMonth = this.onChangeDobMonth.bind(this);
    this.onChangeDobDay = this.onChangeDobDay.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeChapter = this.onChangeChapter.bind(this);
    this.onChangeElementarySchool = this.onChangeElementarySchool.bind(this);
    this.onChangeMiddleSchool = this.onChangeMiddleSchool.bind(this);
    this.onChangeHighSchool = this.onChangeHighSchool.bind(this);
    this.onChangeAdvancedSchool = this.onChangeAdvancedSchool.bind(this);
    this.onChangeBackgroundCheckAck = this.onChangeBackgroundCheckAck.bind(this);
    this.onChangeVolunteerAgreementAck = this.onChangeVolunteerAgreementAck.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.formRef = React.createRef();

    this.state = {
      className: "loading",
      email: "",
      role: "",
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      location: "",
      locations: [],
      chapter: "",
      chapters: [],
      elementarySchool: false,
      middleSchool: false,
      highSchool: false,
      advancedSchool: false,
      backgroundCheckAck: false,
      volunteerAgreementAck: false,
      backgroundCheckStatus: null,
      backgroundCheckAckedAt: null,
      volunteerAgreementAckedAt: null,
      profileUpdateNeeded: false,
    };

    var token = window.sessionStorage.getItem("token");
    var data = { token: token };
    AccountDataService.getProfile(data)
      .then(response => {
        this.setProfile(response);
      }).catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
      });
  }

  setProfile(response) {
    const className = response.data.firstName == null
      || response.data.lastName == null
      || response.data.phone == null
      || response.data.address == null
      || response.data.location == null
      || response.data.elementarySchool == null
      || response.data.middleSchool == null
      || response.data.highSchool == null
      || response.data.advancedSchool == null
      || response.data.profileUpdateNeeded
      ? "edit" : "view";
    const dob = response.data.dob === null ? null : new Date(response.data.dob + "T00:00:00.000Z");
    this.setState({
      className: className,
      email: response.data.email,
      role: response.data.role,
      firstName: this.nullCheck(response.data.firstName),
      lastName: this.nullCheck(response.data.lastName),
      dobYear: dob == null ? "" : dob.getUTCFullYear(),
      dobMonth: dob == null ? "" : dob.getUTCMonth(),
      dobDay: dob == null ? "" : dob.getUTCDate(),
      phone: this.nullCheck(response.data.phone),
      address: this.nullCheck(response.data.address),
      location: "" + this.nullCheck(response.data.location),
      locations: response.data.locations,
      chapter: "" + this.nullCheck(response.data.chapter),
      chapters: response.data.chapters,
      elementarySchool: this.nullCheckCheckbox(response.data.elementarySchool),
      middleSchool: this.nullCheckCheckbox(response.data.middleSchool),
      highSchool: this.nullCheckCheckbox(response.data.highSchool),
      advancedSchool: this.nullCheckCheckbox(response.data.advancedSchool),
      backgroundCheckAck: response.data.backgroundCheckAckedAt != null,
      volunteerAgreementAck: response.data.volunteerAgreementAckedAt != null,
      backgroundCheckStatus: response.data.backgroundCheckStatus,
      backgroundCheckAckedAt: response.data.backgroundCheckAckedAt,
      volunteerAgreementAckedAt: response.data.volunteerAgreementAckedAt,
      profileUpdateNeeded: response.data.profileUpdateNeeded,
    });
    console.log("this.state.profileUpdateNeeded=" + this.state.profileUpdateNeeded);
  }

  nullCheck(d) {
    return d == null ? "" : d;
  }

  nullCheckCheckbox(d) {
    return d == null ? false : d;
  }

  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  onChangeLastName(e) {
    this.setState({ lastName: e.target.value });
  }

  onChangeDobYear(e) {
    this.setState({ dobYear: e.target.value})
  }

  onChangeDobMonth(e) {
    this.setState({ dobMonth: e.target.value})
  }

  onChangeDobDay(e) {
    this.setState({ dobDay: e.target.value})
  }

  onChangePhone(e) {
    this.setState({ phone: e.target.value });
  }

  onChangeAddress(e) {
    this.setState({ address: e.target.value });
  }

  onChangeLocation(e) {
    this.setState({ location: e.target.value });
  }

  onChangeChapter(e) {
    this.setState({ chapter: e.target.value });
  }

  onChangeElementarySchool(e) {
    if (this.state.className === "edit") {
      this.setState({ elementarySchool: !this.state.elementarySchool });
    }
  }

  onChangeMiddleSchool(e) {
    if (this.state.className === "edit") {
      this.setState({ middleSchool: !this.state.middleSchool });
    }
  }

  onChangeHighSchool(e) {
    if (this.state.className === "edit") {
      this.setState({ highSchool: !this.state.highSchool });
    }
  }

  onChangeAdvancedSchool(e) {
    if (this.state.className === "edit") {
      this.setState({ advancedSchool: !this.state.advancedSchool });
    }
  }

  onChangeBackgroundCheckAck(e) {
    if (this.state.className === "edit") {
      this.setState({ backgroundCheckAck: !this.state.backgroundCheckAck });
    }
  }

  onChangeVolunteerAgreementAck(e) {
    if (this.state.className === "edit") {
      this.setState({ volunteerAgreementAck: !this.state.volunteerAgreementAck });
    }
  }

  saveProfile() {
    const node = this.formRef.current;
    node.classList.add('was-validated');
    if (!node.checkValidity()) {
      return;
    }
    node.classList.remove("was-validated");
    var data = {
      token: window.sessionStorage.getItem("token"),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dob: "" + this.state.dobYear + "-" + this.pad0(parseInt(this.state.dobMonth) + 1) + "-" + this.pad0(parseInt(this.state.dobDay)),
      phone: this.state.phone,
      address: this.state.address,
      location: this.state.location === "" ? null : Number(this.state.location),
      chapter: this.state.chapter === "" ? null : Number(this.state.chapter),
      elementarySchool: this.state.elementarySchool,
      middleSchool: this.state.middleSchool,
      highSchool: this.state.highSchool,
      advancedSchool: this.state.advancedSchool,
      backgroundCheckAck: this.state.backgroundCheckAck,
      volunteerAgreementAck: this.state.volunteerAgreementAck,
    };
    AccountDataService.updateProfile(data)
      .then(response => {
        this.setProfile(response);
        this.setState({ className: "view" });
        var snackbar = document.getElementById("snackbar");
        snackbar.className = "show";
        setTimeout(function () {
          snackbar.className = snackbar.className.replace("show", "");
          window.location.reload(); // Workaround for duplicating children glitch
        }, 1000);
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
      });
  }

  onClickEdit(e) {
    const node = this.formRef.current;
    node.classList.remove('was-validated');
    this.setState({ className: "edit" });
  }

  pad0(n) {
    return n < 10 ? "0" + n : "" + n;
  }

  getBackgroundCheckClassName(status) {
    if (status === "notNeeded") {
      return "badge text-bg-success";
    } else if (status === "pending") {
      return "badge text-bg-info";
    } else if (status === "passed") {
      return "badge text-bg-success";
    } else if (status === "rejected") {
      return "badge text-bg-danger";
    } else if (status === "expired") {
      return "badge text-bg-danger";
    } else {
      return "";
    }
  }

  getBackgroundCheckText(status) {
    if (status === "notNeeded") {
      return "Not needed";
    } else if (status === "pending") {
      return "Pending";
    } else if (status === "passed") {
      return "Passed";
    } else if (status === "rejected") {
      return "Rejected";
    } else if (status === "expired") {
      return "Expired";
    } else {
      return "";
    }
  }

  getYears() {
    var years = [];
    for (var index = 0; index < 100; ++index) {
      years.push(2013 - index);
    }
    return years;
  }

  getMonths() {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  }

  getDays() {
    var days = [];
    for (var index = 0; index < 31; ++index) {
      days.push(index);
    }
    return days;
  }

  render() {
    return (
      <div className="fancy-box">
        <div className={this.state.className}>
          <h1 className="mb-3">Profile {this.state.role && (<span>[{this.state.role}]</span>)}</h1>
          <div className="loading mb-3">
            <p>Loading</p>
          </div>
          <div className="viewedit registration-form">
            {this.state.profileUpdateNeeded && (
              <div className="small badge rounded-pill text-bg-warning mb-3">Please confirm that &quot;Grade Level&quot; and all other details correct and click Save.</div>
            )}
            <form className="row needs-validation" noValidate ref={this.formRef}>
              <div className="col-12 mb-3">
                <button type="button" className="btn btn-secondary view" onClick={this.onClickEdit}>Edit Profile</button>
                <button type="button" className="btn green-button edit" onClick={this.saveProfile}>Save Profile</button>
                <Link to={"/reset-password"}><button className="btn btn-light">Reset Password</button></Link>
              </div>

              {/* Information */}
              <div className="row">

                {/* Left column */}
                <div className="col-12 col-lg-6">

                  {/* Email */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="text" className="form-control" id="email" disabled required value={this.state.email} name="email" aria-describedby="emailHelp" />
                  </div>

                  {/* First name */}
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">First name</label>
                    <input type="text" className="form-control" id="firstName" required value={this.state.firstName} onChange={this.onChangeFirstName} name="firstName" aria-describedby="firstNameHelp" />
                    <div className="invalid-feedback">Please provide your first name</div>
                  </div>

                  {/* Last name */}
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Last name</label>
                    <input type="text" className="form-control" id="lastName" required value={this.state.lastName} onChange={this.onChangeLastName} name="lastName" />
                    <div className="invalid-feedback">Please provide your last name</div>
                  </div>

                  {/* DOB */}
                  <div className="row">
                    <div className="col-4 mb-3">
                      <label htmlFor="dobMonth" className="form-label">DOB</label>
                      <select id="dobMonth" name="dobMonth" className="form-select" required value={this.state.dobMonth} onChange={this.onChangeDobMonth}>
                        <option value="">--</option>
                        {this.getMonths().map((item, index) => (
                          <option key={index} value={index}>{item}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-4 mb-3">
                      <label htmlFor="dobDay" className="form-label">Day</label>
                      <select id="dobDay" name="dobDay" className="form-select" required value={this.state.dobDay} onChange={this.onChangeDobDay}>
                        <option value="">--</option>
                        {this.getDays().map(item => (
                          <option key={item + 1} value={item + 1}>{item + 1}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-4 mb-3">
                      <label htmlFor="dobYear" className="form-label">Year</label>
                      <select id="dobYear" name="dobYear" className="form-select" required value={this.state.dobYear} onChange={this.onChangeDobYear}>
                        <option value="">--</option>
                        {this.getYears().map(item => (
                          <option key={item} value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Phone */}
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input type="text" className="form-control" id="phone" required value={this.state.phone} onChange={this.onChangePhone} name="phone" />
                    <div className="invalid-feedback">Please provide your phone number</div>
                  </div>

                  {/* Address */}
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">Address</label><span className="form-text"> (e.g. 123 Main St, Dallas, TX 75201)</span>
                    <input type="text" className="form-control" id="address" required value={this.state.address} onChange={this.onChangeAddress} name="address" />
                    <div className="invalid-feedback">Please provide your address to be able to run the background check</div>
                  </div>
                </div>

                {/* Right column */}
                <div className="col-12 col-lg-6">

                  {/* Location */}
                  <div className="mb-3">
                    <label className="form-label">Branch</label> <span className="form-text">(<a href="https://www.intellichoice.org/branches/" rel="noreferrer" target="_blank">See locations and schedules</a>)</span>
                    <div className="mb-0">
                      <select className="mb-0 form-select" id="location" required value={this.state.location} onChange={this.onChangeLocation} name="location">
                        <option key="" value="">--- Select --</option>
                        {this.state.locations.map(l => (<option key={l.id} value={l.id}>{l.name}</option>))}
                      </select>
                    </div>
                    <div className="form-text">We strongly recommend In-Person branches.</div>
                  </div>

                  {/* Chapter */}
                  <div className="mb-3">
                    <label className="form-label">Chapter</label>
                    <div className="mb-0">
                      <select className="mb-0 form-select" id="chapter" value={this.state.chapter} onChange={this.onChangeChapter} name="chapter">
                        <option key="" value="">--- Select --</option>
                        {this.state.chapters.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}
                      </select>
                    </div>
                  </div>

                  {/* Grade */}
                  <div className="mb-3">
                    <label className="form-label">Grade levels you would like to tutor</label>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="elementarySchool" checked={this.state.elementarySchool} onChange={this.onChangeElementarySchool} name="elementarySchool" aria-describedby="elementarySchoolHelp" />
                      <label htmlFor="elementarySchool" className="form-check-label">Elementary school</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="middleSchool" checked={this.state.middleSchool} onChange={this.onChangeMiddleSchool} name="middleSchool" aria-describedby="middleSchoolHelp" />
                      <label htmlFor="middleSchool" className="form-check-label">Middle school</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="highSchool" checked={this.state.highSchool} onChange={this.onChangeHighSchool} name="highSchool" aria-describedby="highSchoolHelp" />
                      <label htmlFor="highSchool" className="form-check-label">High school</label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="advancedSchool" checked={this.state.advancedSchool} onChange={this.onChangeAdvancedSchool} name="advancedSchool" aria-describedby="advancedSchoolHelp" />
                      <label htmlFor="advancedSchool" className="form-check-label">Advanced high school (Calculus, Statistics)</label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Agreements</label>
                    <div className="form-check">
                      <input id="backgroundCheckAck" name="backgroundCheckAck" type="checkbox" className="form-check-input" required disabled={this.state.backgroundCheckAckedAt != null} checked={this.state.backgroundCheckAck} onChange={this.onChangeBackgroundCheckAck} />
                      <label htmlFor="backgroundCheckAck" className="form-check-label">IntelliChoice performs background check for all our tutors over 18 years.  By checking this, you authorize IntelliChoice, Inc. to run background check for you.</label>
                    </div>
                    <div className="form-check">
                      <input id="volunteerAgreementAck" name="volunteerAgreementAck" type="checkbox" className="form-check-input" required disabled={this.state.volunteerAgreementAckedAt != null} checked={this.state.volunteerAgreementAck} onChange={this.onChangeVolunteerAgreementAck} />
                      <label htmlFor="volunteerAgreementAck" className="form-check-label">I have read and agreed to <a href="https://intellichoice.org/volunteer-agreement" rel="noreferrer" target="_blank">IntelliChoice Volunteer Agreement Form</a></label>
                    </div>
                  </div>

                  {/* Background check */}
                  {this.state.backgroundCheckStatus &&
                  <div className="mb-3">
                    <label className="form-label">Background check</label>
                    <div>
                      <span className={this.getBackgroundCheckClassName(this.state.backgroundCheckStatus)}>{this.getBackgroundCheckText(this.state.backgroundCheckStatus)}</span>
                    </div>
                  </div>}

                </div>
              </div>
            </form>
          </div>
        </div>
        <div id="snackbar">Profile saved!</div>
      </div>
    );
  }
}

export default withRouter(ProfileTutor);