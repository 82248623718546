import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";
import { Link } from "react-router-dom";
import "../App.css"

class RegisterAccount extends Component {
  constructor(props) {
    super(props);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.registerAccount = this.registerAccount.bind(this);
    this.newAccount = this.newAccount.bind(this);
    this.formRef = React.createRef();

    this.state = {
      role: "",
      email: "",
      password: "",
      errorMessage: "",
    };
  }

  onChangeRole(e) {
    this.setState({
      role: e.target.value,
      errorMessage: "",
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
      errorMessage: "",
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
      errorMessage: "",
    });
  }

  registerAccount() {
    const node = this.formRef.current;
    node.classList.add('was-validated');
    if (!node.checkValidity()) {
      node.reportValidity(); // For radio buttons
      return;
    }
    var data = {
      role: this.state.role,
      email: this.state.email,
      password: this.state.password
    };
    AccountDataService.register(data)
      .then(response => {
        console.log(response.data);
        window.sessionStorage.setItem("email", data.email);
        this.props.router.navigate("/verify-email");
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
        this.setState({ errorMessage: e.response.data.message });
        node.classList.remove('was-validated');
      });
  }

  newAccount() {
    this.setState({
      email: "",
      password: "",
    });
  }

  render() {
    return (
      <div className="center-center fancy-box">
        <h1 className="mb-3">Register</h1>
        <form className="needs-validation" noValidate ref={this.formRef}>
          <div className="mb-1">Please select your role. Note that <strong>roles cannot be changed after signup</strong>.</div>
          <div className="mb-3 form-check ">
            <ul className="radio-container">
              <li>
                <input type="radio" className="form-check-input" id="Parent" value="Parent" name="role" checked={this.state.role === "Parent"} onChange={this.onChangeRole} required />
                <label for="Parent">Parent</label>
                <p className="description">Parents may create on account and add one or more students who will receive tutoring. Parents will manage weekly sign ups for children.</p>
              </li>
              <li>
                <input type="radio" className="form-check-input" id="Student" value="Student" name="role" checked={this.state.role === "Student"} onChange={this.onChangeRole} />
                <label for="Student">Student</label>
                <p className="description">"Student" role should be selected for those receiving tutoring by Intellichoice and manage your own weekly signups</p>
              </li>
              <li>
                <input type="radio" className="form-check-input" id="Tutor" value="Tutor" name="role" checked={this.state.role === "Tutor"} onChange={this.onChangeRole} />
                <label for="Tutor">Tutor</label>
                <p className="description">All who wish to be volunteer as Tutors (regardless of whether you are a college or high school student or an adult), please select the role as "Tutor"</p>
              </li>
            </ul>
          </div>
          <div className="mb-3">
            <input type="email" className="form-control" id="email" placeholder="Email Address" value={this.state.email} onChange={this.onChangeEmail} name="email" aria-describedby="emailHelp" required />
            <div className="invalid-feedback">Please provide a valid email address</div>
            {this.state.errorMessage && <span className="text-danger">Email already in use </span>}
          </div>
          <div className="mb-3">
            <input type="password" className="form-control" id="password" placeholder="Password" minLength="8" value={this.state.password} onChange={this.onChangePassword} name="password" required />
            <div className="invalid-feedback">Please provide a password with 8 or more characters</div>
          </div>
          <button type="button" className="btn btn-primary green-button" onClick={this.registerAccount}>Submit</button>
          <div className="bottom-text">Already have an account? <Link to={"/login"}>Login</Link></div>
        </form>
      </div>
    );
  }
}

export default withRouter(RegisterAccount);