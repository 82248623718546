import React, { Component } from "react";
import { withRouter } from "../common/with-router";
import { Link } from "react-router-dom";
import http from "../http-common";
import ReportService from "../services/report.service"

class Reports extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.onShowSessions = this.onShowSessions.bind(this);
    this.onShowBranches = this.onShowBranches.bind(this);
    this.onShowSignups = this.onShowSignups.bind(this);
    this.onShowAttendance = this.onShowAttendance.bind(this);
    this.onChangeSessionDate = this.onChangeSessionDate.bind(this);
    this.onShowChapters = this.onShowChapters.bind(this);
    this.onShowChapterLeaders = this.onShowChapterLeaders.bind(this);
    this.onShowChapterVolunteers = this.onShowChapterVolunteers.bind(this);
    this.onShowChapterAttendance = this.onShowChapterAttendance.bind(this);
    var token = window.sessionStorage.getItem("token");
    this.state = {
      baseURL: http.defaults.baseURL + "/reports",
      token: token,
      tab: "sessions",
      chaptertab: "leaders",
      sessionstab: "signups",
      sessionDates: null,
      sessionDate: null,
      signups: null,
      attendance: null,
      branches: null
    };
    ReportService.listSessionDates()
    .then(resp => {
      const sessionDates = resp.data.sessionDates;
      const sessionDate = this.findUpcomingSessionDate(sessionDates);
      this.setState({sessionDates: sessionDates.reverse(), sessionDate: sessionDate});
      this.loadSignups(sessionDate);
    })
    .catch(this.handleError);
  }

  loadSignups(sessionDate) {
    ReportService.listSignups(sessionDate)
    .then(resp => {
      const signups = this.convertSignupsToTable(resp.data.signups);
      this.setState({signups: signups});
    })
    .catch(this.handleError);
  }

  loadAttendance(sessionDate) {
    ReportService.listAttendance(sessionDate)
    .then(resp => {
      const attendance = this.convertAttendanceToTable(resp.data.attendance);
      this.setState({attendance: attendance});
    })
    .catch(this.handleError);
  }

  loadBranches() {
    ReportService.listBranches()
    .then(resp => {
      const branches = resp.data.branches;
      this.setState({branches: branches});
    })
    .catch(this.handleError);
  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/login");
    }
  }

  findUpcomingSessionDate(sessionDates) {
    if (sessionDates.length === 0) {
      return null;
    }
    const now = new Date().getTime();
    for (const sessionDate of sessionDates) {
      if (new Date(sessionDate.startDate).getTime() > now) {
        return sessionDate.startDate;
      }
    }
    return sessionDates[sessionDates.length - 1].startDate;
  }

  convertSignupsToTable(signups) {
    if (signups.length === 0) {
      return [];
    }
    const table = [];
    const total = {name: null, tutors: 0, students: 0};
    var row = null;
    for (const signup of signups) {
      const name = signup.name;
      if ((row === null) || (name !== row.name)) {
        if (row !== null) {
          total.tutors += row.tutors;
          total.students += row.students;
          table.push(row);
        }
        row = {name: signup.name, tutors: 0, students: 0};
      }
      if (signup.role === "Manager" || signup.role === "Tutor") {
        row.tutors += signup.count;
      } else {
        row.students += signup.count;
      }
    }
    total.tutors += row.tutors;
    total.students += row.students;
    table.push(row);
    table.push(total);
    return table;
  }

  convertAttendanceToTable(attendance) {
    if (attendance.length === 0) {
      return [];
    }
    const table = [];
    const total = {name: null, attended: 0, notAttended: 0, pending: 0}
    var row = null;
    for (const att of attendance) {
      const name = att.name;
      if ((row === null) || (name !== row.name)) {
        if (row !== null) {
          total.attended += row.attended;
          total.notAttended += row.notAttended;
          total.pending += row.pending;
          table.push(row);
        }
        row = {name: att.name, attended: 0, notAttended: 0, pending: 0};
      }
      if (att.attended === 1) {
        row.attended += att.count;
      } else if (att.attended === 0) {
        row.notAttended += att.count;
      } else {
        row.pending += att.count;
      }
    }
    total.attended += row.attended;
    total.notAttended += row.notAttended;
    total.pending += row.pending;
    table.push(row);
    table.push(total);
    return table;
  }

  onShowSessions(e) {
    this.setState({tab: "sessions"});
  }

  onShowBranches(e) {
    this.setState({tab: "branches"});
    if (this.state.branches === null) {
      this.loadBranches();
    }
  }

  onShowSignups(e) {
    this.setState({sessionstab: "signups"});
    if (this.state.signups === null) {
      this.loadSignups(this.state.sessionDate);
    }
  }

  onShowAttendance(e) {
    this.setState({sessionstab: "attendance"});
    if (this.state.attendance === null) {
      this.loadAttendance(this.state.sessionDate);
    }
  }

  onChangeSessionDate(e) {
    const sessionDate = e.target.value;
    this.setState({sessionDate: sessionDate, signups: null, attendance: null});
    if (this.state.sessionstab === 'signups') {
      this.loadSignups(sessionDate);
    } else if (this.state.sessionstab === 'attendance') {
      this.loadAttendance(sessionDate);
    }
  }

  onShowChapters(e) {
    this.setState({tab: "chapters"});
  }

  onShowChapterLeaders(e) {
    this.setState({chaptertab: "leaders"});
  }

  onShowChapterVolunteers(e) {
    this.setState({chaptertab: "volunteers"});
  }

  onShowChapterAttendance(e) {
    this.setState({chaptertab: "attendance"});
  }

  render() {
    return (
      <div className="fancy-box">
        <h1 className="mb-3">Reports</h1>
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "sessions" ? " active" : "")} aria-current="page" onClick={this.onShowSessions}>Sessions</button>
          </li>
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "branches" ? " active" : "")} aria-current="page" onClick={this.onShowBranches}>Branches</button>
          </li>
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "chapters" ? " active" : "")} aria-current="page" onClick={this.onShowChapters}>Chapters</button>
          </li>
        </ul>
        {this.state.tab === "sessions" && (
          <div>
            <select className="form-select mb-3" value={this.state.sessionDate ? this.state.sessionDate : ""} onChange={this.onChangeSessionDate}>
              {!this.state.sessionDates && (<option>Loading...</option>)}
              {this.state.sessionDates?.length === 0 && (<option>No sessions yet</option>)}
              {this.state.sessionDates?.map(sessionDate => (
                <option key={sessionDate.startDate} value={sessionDate.startDate}>{sessionDate.startDate}</option>
              ))}
            </select>
            <ul className="nav nav-tabs mb-3">
              <li className="nav-item">
                <button className={"nav-link" + (this.state.sessionstab === "signups" ? " active" : "")} aria-current="page" onClick={this.onShowSignups}>Sign-ups</button>
              </li>
              <li className="nav-item">
                <button className={"nav-link" + (this.state.sessionstab === "attendance" ? " active" : "")} aria-current="page" onClick={this.onShowAttendance}>Attendance</button>
              </li>
            </ul>
            {this.state.sessionstab === 'signups' && (
              <div className="mb-3 table-responsive">
                <table className="table table-striped has-totals">
                  <thead>
                    <tr>
                      <th>Branch</th>
                      <th className="text-center">Tutors</th>
                      <th className="text-center">Students</th>
                      <th className="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.signups === null && (
                      <tr>
                        <td colSpan="4">Loading...</td>
                      </tr>
                    )}
                    {this.state.signups?.length === 0 && (
                      <tr>
                        <td colSpan="4">No signups</td>
                      </tr>
                    )}
                    {this.state.signups?.map(signup => (
                      <tr>
                        <td>{signup.name === null ? "Total" : signup.name}</td>
                        <td className="text-center">{signup.tutors}</td>
                        <td className="text-center">{signup.students}</td>
                        <td className="text-center">{signup.tutors + signup.students}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {this.state.sessionstab === 'attendance' && (
              <div className="mb-3 table-responsive">
                <table className="table table-striped has-totals">
                  <thead>
                    <tr>
                      <th>Branch</th>
                      <th className="text-center">Attended</th>
                      <th className="text-center">Not Attended</th>
                      <th className="text-center">Pending</th>
                      <th className="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.attendance === null && (
                      <tr>
                        <td colSpan="5">Loading...</td>
                      </tr>
                    )}
                    {this.state.attendance?.length === 0 && (
                      <tr>
                        <td colSpan="5">No signups</td>
                      </tr>
                    )}
                    {this.state.attendance?.map(att => (
                      <tr>
                        <td>{att.name === null ? "Total" : att.name}</td>
                        <td className="text-center">{att.attended}</td>
                        <td className="text-center">{att.notAttended}</td>
                        <td className="text-center">{att.pending}</td>
                        <td className="text-center">{att.attended + att.notAttended + att.pending}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div>Complete member list <Link to={this.state.baseURL + "/members?token=" + this.state.token} target="_blank">Download</Link></div>
          </div>
        )}
        {this.state.tab === "branches" && (
          <div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Branch</th>
                  <th></th>
                  <th>Managers</th>
                  <th className="text-center">Tutors</th>
                  <th className="text-center">Students</th>
                </tr>
              </thead>
              <tbody>
                {this.state.branches === null && (
                  <tr>
                    <td>Loading...</td>
                  </tr>
                )}
                {this.state.branches !== null && this.state.branches.map(branch => (
                  <tr>
                    <td>
                      {branch.name}
                    </td>
                    <td>
                      {branch.active === 1 && (<span className="small badge rounded-pill text-bg-primary">Active</span>)}
                      {branch.active === 0 && (<span className="small badge rounded-pill text-bg-secondary mr-1">Inactive</span>)}
                      &nbsp;
                      {branch.online === 1 && (<span className="small badge rounded-pill text-bg-secondary">Online</span>)}
                      {branch.online === 0 && (<span className="small badge rounded-pill text-bg-primary">In-person</span>)}
                    </td>
                    <td>
                      {branch.managers.map(manager => (
                        <div>{manager.firstName} {manager.lastName} ({manager.email})</div>
                      ))}
                    </td>
                    <td className="text-center">{branch.members?.tutors || 0}</td>
                    <td className="text-center">{branch.members?.students || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.state.tab === "chapters" && (
          <div>
            <h3>Coppell High School</h3>
            <ul className="nav nav-tabs mb-3">
              <li className="nav-item">
                <button className={"nav-link" + (this.state.chaptertab === "leaders" ? " active" : "")} aria-current="page" onClick={this.onShowChapterLeaders}>Leaders</button>
              </li>
              <li className="nav-item">
                <button className={"nav-link" + (this.state.chaptertab === "volunteers" ? " active" : "")} aria-current="page" onClick={this.onShowChapterVolunteers}>Volunteers</button>
              </li>
              <li className="nav-item">
                <button className={"nav-link" + (this.state.chaptertab === "attendance" ? " active" : "")} aria-current="page" onClick={this.onShowChapterAttendance}>Attendance</button>
              </li>
            </ul>
            {this.state.chaptertab === "leaders" && (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>President</td>
                      <td>Tutor Name1</td>
                    </tr>
                    <tr>
                      <td>Vice President</td>
                      <td>Tutor Name2</td>
                    </tr>
                    <tr>
                      <td>Tresurer</td>
                      <td>Tutor Name3</td>
                    </tr>
                    <tr>
                      <td>Secretery</td>
                      <td>Tutor Name4</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {this.state.chaptertab === "volunteers" && (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name1</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>Name2</td>
                      <td>11</td>
                    </tr>
                    <tr>
                      <td>Name3</td>
                      <td>9</td>
                    </tr>
                    <tr>
                      <td>Name4</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td>Name5</td>
                      <td>9</td>
                    </tr>
                    <tr>
                      <td>Name6</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>Name7</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td>Name8</td>
                      <td>11</td>
                    </tr>
                    <tr>
                      <td>Name9</td>
                      <td>11</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {this.state.chaptertab === "attendance" && (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Hours</th>
                      <th>Sessions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name1</td>
                      <td>10</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>Name2</td>
                      <td>20</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>Name3</td>
                      <td>5</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>Name4</td>
                      <td>4</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>Name5</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Name6</td>
                      <td>10</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>Name7</td>
                      <td>15</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>Name8</td>
                      <td>25</td>
                      <td>9</td>
                    </tr>
                    <tr>
                      <td>Name9</td>
                      <td>35</td>
                      <td>12</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

}

export default withRouter(Reports);