import React, { Component } from "react";
import { withRouter } from "../common/with-router";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="center-center fancy-box">
        <h1 className="mb-3">
          Welcome to IntelliChoice
        </h1>
        <p>
          Our mission is to support economically disadvantaged children in order to help them attain scholarly excellence. We aim to develop in them an interest in mathematics as a means to prepare them for college and successful careers in any field.
        </p>
      </div>
    );
  }
}

export default withRouter(Welcome);
