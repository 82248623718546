import http from "../http-common";

class ReportService {
  listSessionDates() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/reports/sessionDates", {params: {token: token}});
  }
  listSignups(sessionDate) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/reports/signups", {params: {sessionDate: sessionDate, token: token}});
  }
  listAttendance(sessionDate) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/reports/attendance", {params: {sessionDate: sessionDate, token: token}});
  }
  listBranches() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/reports/branches", {params: {token: token}});
  }
}

export default new ReportService();
