import http from "../http-common";

class AccountDataService {
  register(data) {
    return http.post("/accounts", data);
  }
  verifyEmail(data) {
    return http.put("/accounts/code", data);
  }
  resendCode(data) {
    return http.post("/accounts/resendCode", data);
  }
  login(data) {
    return http.post("/accounts/login", data);
  }
  getProfile(data) {
    return http.get("/accounts", { params: data });
  }
  updateProfile(data) {
    return http.put("/accounts", data);
  }
  getSignups(data) {
    return http.get("/accounts/signups", {params: data});
  }
  saveSignups(data) {
    return http.put("/accounts/signups", data);
  }
  resetPassword(data) {
    return http.put("/accounts/resetPassword", data);
  }
  sendCode(data) {
    return http.put("/accounts/sendCode", data);
  }
  forgotPassword(data) {
    return http.put("/accounts/forgotPassword", data);
  }
}

export default new AccountDataService();
