import React, { Component } from "react";
import { Clipboard } from 'react-bootstrap-icons';
import { withRouter } from "../common/with-router";
import AttendanceService from "../services/attendance.service";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.showSessions = this.showSessions.bind(this);
    this.showMembers = this.showMembers.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeAttendance = this.onChangeAttendance.bind(this);
    this.onSaveAttendance = this.onSaveAttendance.bind(this);
    this.onRevertAttendanceChanges = this.onRevertAttendanceChanges.bind(this);
    this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
    this.onCopySignupsToClipboard = this.onCopySignupsToClipboard.bind(this);
    this.state = {
      tab: "sessions",
      location: null,
      calendars: null,
      calendarId: null,
      signups: null,
      accounts: null,
      attendanceChanges: {},
    };
    AttendanceService.listDates()
    .then(resp => {
      const location = resp.data.location;
      const calendars = resp.data.calendars;
      const calendarId = this.findFirstPastCalendarId(calendars.toReversed());
      this.setState({location: location, calendars: calendars, calendarId: calendarId});
      this.getSignups(calendarId);
    }).catch(this.handleError);
    AttendanceService.listMembers()
    .then(resp => {
      var emails = "";
      for (const account of resp.data.accounts) {
        if (emails !== "") {
          emails = emails + ', ';
        }
        emails = emails + '"' + account.firstName + ' ' + account.lastName + '" <' + account.email + '>';
      }
      this.setState({accounts: resp.data.accounts, emails: emails});
    }).catch(this.handleError);
  }

  getSignups(calendarId) {
    this.setState({signups: null, attendanceChanges: {}})
    AttendanceService.listSignups(calendarId)
    .then(resp => {
      var emails = "";
      for (const signup of resp.data.signups) {
        const email = '"' + signup.account.firstName + ' ' + signup.account.lastName + '" <' + signup.account.email + '>';
        if (emails.indexOf(email) === -1) {
          if (emails !== "") {
            emails = emails + ', ';
          }
          emails = emails + email;
        }
      }
      this.setState({signups: resp.data.signups, signupEmails: emails});
    })
    .catch(this.handleError);
  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/login");
    } else {
      console.log(e);
    }
  }

  findFirstPastCalendarId(calendars) {
    const now = new Date();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    for (var index = 0; index < calendars.length; ++index) {
      if (new Date(calendars[index].startTime) > yesterday) {
        return calendars[index].id;
      }
    }
    return calendars.length === 0 ? null : calendars[calendars.length - 1].id;
  }

  showSessions(e) {
    this.setState({tab: "sessions"});
  }

  showMembers(e) {
    this.setState({tab: "members"});
  }

  onChangeLocation(e) {
    const calendarId = parseInt(e.target.value);
    this.setState({calendarId: calendarId, signups: null});
    this.getSignups(calendarId);
  }

  onChangeAttendance(e) {
    const attended = e.target.value === "1";
    const signupId = parseInt(e.target.name.split("-")[1]);
    const signup = this.findSignup(signupId);
    const attendanceChanges = this.state.attendanceChanges;
    if (signup.attended === attended) {
      delete attendanceChanges[e.target.name];
    } else {
      attendanceChanges[e.target.name] = attended;
    }
    this.setState({attendanceChanges: attendanceChanges});
  }

  onSaveAttendance() {
    AttendanceService.saveAttendance(this.state.attendanceChanges)
    .then(resp => {
      this.getSignups(this.state.calendarId);
    })
    .catch(this.handleError);
  }

  onRevertAttendanceChanges() {
    this.setState({attendanceChanges: {}});
  }

  onCopyToClipboard() {
    navigator.clipboard.writeText(this.state.emails);
  }

  onCopySignupsToClipboard() {
    navigator.clipboard.writeText(this.state.signupEmails);
  }

  findSignup(id) {
    for (var index = 0; index < this.state.signups.length; ++index) {
      if (this.state.signups[index].id === id) {
        return this.state.signups[index];
      }
    }
    return null;
  }

  formatDatetime(datetime) {
    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric'}).format(new Date(datetime));
  }

  signup2name(signup) {
    return "signup-" + signup.id;
  }

  signup2id(signup, yes) {
    return this.signup2name(signup) + "-" + (yes ? "1" : "0");
  }

  getGradeLevel(signup) {
    var gradeLevel = null;
    if (signup.account.role === "Student") {
      gradeLevel = signup.account.gradeLevel;
    }
    else if (signup.child !== null) {
      gradeLevel = signup.child.gradeLevel;
    }
    return gradeLevel === null ? "" : gradeLevel;
  }

  isChecked(signup, q) {
    var checked = this.state.attendanceChanges["signup-" + signup.id];
    if (checked === undefined) {
      checked = signup.attended;
    }
    return checked === q;
  }

  findSelectedCalendar() {
    for (const calendar of this.state.calendars) {
      if (calendar.id === this.state.calendarId) {
        return calendar;
      }
    }
    return null;
  }

  isSelectedSessionInFuture() {
    if (this.state.calendars === null || this.state.calendars.length === 0) {
      return false;
    }
    const now = new Date(new Date().getTime() + 60 * 60 * 1000);
    const sessionDate = new Date(this.findSelectedCalendar().startTime);
    return sessionDate > now;
  }

  render() {
    return (
      <div className="fancy-box">
        <h1 className="mb-3">Branch Mgmt - {this.state.location?.name}</h1>
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "sessions" ? " active" : "")} aria-current="page" onClick={this.showSessions}>Attendance</button>
          </li>
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "members" ? " active" : "")} aria-current="page" onClick={this.showMembers}>Members</button>
          </li>
        </ul>
        {this.state.tab === "sessions" && (
          <div>
            <form className="needs-validation" noValidate ref={this.formRef}>
              <div className="mb-3">
                <select className="form-select" value={this.state.calendarId ? this.state.calendarId : ""} disabled={Object.keys(this.state.attendanceChanges).length > 0 && ("disabled")} onChange={this.onChangeLocation}>
                  {!this.state.calendars && (<option>Loading...</option>)}
                  {this.state.calendars?.length === 0 && (<option>No sessions yet</option>)}
                  {this.state.calendars?.map(calendar => (
                    <option key={calendar.id} value={calendar.id}>{this.formatDatetime(calendar.startTime)}</option>
                  ))}
                </select>
              </div>
              <h5 className="mb-3">
                Signups
                {Object.keys(this.state.attendanceChanges).length > 0 && (<span className="small badge rounded-pill text-bg-warning">(Don't forget to save your changes)</span>)}
                {this.isSelectedSessionInFuture() && (<span className="small badge rounded-pill text-bg-warning">(Cannot record attendance yet because the selected session is in the future)</span>)}
              </h5>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Child</th>
                      <th>Grade</th>
                      <th>Attended</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.signups?.length === 0 && (
                      <tr><td colSpan="6">No signups</td></tr>
                    )}
                    {this.state.signups?.map(signup => (
                      <tr>
                        <td>
                          {signup.account.firstName} {signup.account.lastName}
                        </td>
                        <td>
                          {signup.account.email}
                        </td>
                        <td>
                          {signup.account.role}
                        </td>
                        <td>
                          {signup.child?.name}
                        </td>
                        <td>
                          {this.getGradeLevel(signup)}
                        </td>
                        <td>
                          {this.isSelectedSessionInFuture() || (
                            <div>
                              <div className="form-check form-check-inline">
                                <input id={this.signup2id(signup, true)} name={this.signup2name(signup)} value="1" type="radio" checked={this.isChecked(signup, true)} className="form-check-input" onChange={this.onChangeAttendance} />
                                <label htmlFor={this.signup2id(signup, true)} className="form-check-label">Yes</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input id={this.signup2id(signup, false)} name={this.signup2name(signup)} value="0" type="radio" checked={this.isChecked(signup, false)} className="form-check-input" onChange={this.onChangeAttendance} />
                                <label htmlFor={this.signup2id(signup, false)} className="form-check-label">No</label>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {this.isSelectedSessionInFuture() || (
                <div className="mb-3">
                  <button type="button" className="btn btn-primary" disabled={Object.keys(this.state.attendanceChanges).length === 0 && ("disabled")} onClick={this.onSaveAttendance}>Save Changes</button>
                  <button type="button" className="btn btn-secondary" disabled={Object.keys(this.state.attendanceChanges).length === 0 && ("disabled")} onClick={this.onRevertAttendanceChanges}>Revert</button>
                </div>
              )}
            </form>
            <div>
              <span className="me-2 link" title="Copy to Clipboard" onClick={this.onCopySignupsToClipboard}><Clipboard /> Copy emails to Clipboard</span>
            </div>
          </div>
        )}
        {this.state.tab === "members" && (
          <div>
            <div className="member-list table-responsive mb-3">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      Name
                    </th>
                    <th>
                      Email
                    </th>
                    <th>Role</th>
                    <th>Child(ren)</th>
                    <th>Registration Date</th>
                    <th>Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.accounts?.map(account => (
                    <tr>
                      <td>{account.firstName} {account.lastName}</td>
                      <td>{account.email}</td>
                      <td>{account.role}<br/><span className="form-text">{account.gradeLevel !== null && ("(Grade " + account.gradeLevel + ")")}</span></td>
                      <td>
                        {account.children.map(child => (
                          <div><label>{child.name} <span className="form-text">(Grade {child.gradeLevel})</span></label></div>
                        ))}
                      </td>
                      <td>{new Intl.DateTimeFormat('en-US', {dateStyle: 'medium'}).format(new Date(account.createdAt))}</td>
                      <td>{account.lastLoggedinAt && (<span>{new Intl.DateTimeFormat('en-US', {dateStyle: 'medium'}).format(new Date(account.lastLoggedinAt))}</span>)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mb-3">
              <span className="me-2 link" title="Copy ALL emails to Clipboard" onClick={this.onCopyToClipboard}><Clipboard /> Copy ALL emails to Clipboard</span>
            </div>
          </div>
        )}
      </div>
    );
  }

}

export default withRouter(Attendance);