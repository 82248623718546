import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";
import { Link } from "react-router-dom";

class ProfileStudent extends Component {

  constructor(props) {
    super(props);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeGrade = this.onChangeGrade.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.formRef = React.createRef();

    this.state = {
      className: "loading",
      email: "",
      role: "",
      firstName: "",
      lastName: "",
      phone: "",
      location: "",
      locations: [],
      elementarySchool: false,
      middleSchool: false,
      highSchool: false,
      advancedSchool: false,
      gradeLevel: "",
      gradeLevels: [
        "Kindergarten",
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th"
      ],
      profileUpdateNeeded: false,
    };

    var token = window.sessionStorage.getItem("token");
    var data = { token: token };
    AccountDataService.getProfile(data)
      .then(response => {
        const className = response.data.firstName == null
          || response.data.lastName == null
          || response.data.phone == null
          || response.data.location == null
          || response.data.gradeLevel == null
          || response.data.profileUpdateNeeded
          ? "edit" : "view";
        this.setState({
          className: className,
          email: response.data.email,
          role: response.data.role,
          firstName: this.nullCheck(response.data.firstName),
          lastName: this.nullCheck(response.data.lastName),
          phone: this.nullCheck(response.data.phone),
          location: "" + this.nullCheck(response.data.location),
          locations: response.data.locations,
          elementarySchool: this.nullCheckCheckbox(response.data.elementarySchool),
          middleSchool: this.nullCheckCheckbox(response.data.middleSchool),
          highSchool: this.nullCheckCheckbox(response.data.highSchool),
          advancedSchool: this.nullCheckCheckbox(response.data.advancedSchool),
          gradeLevel: this.nullCheck(response.data.gradeLevel),
          profileUpdateNeeded: response.data.profileUpdateNeeded,
        });
      }).catch(e => {
        if (!e.response) { console.log("error" + this.state); return; }
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
      });
  }

  nullCheck(d) {
    return d == null ? "" : d;
  }

  nullCheckCheckbox(d) {
    return d == null ? false : d;
  }

  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  onChangeLastName(e) {
    this.setState({ lastName: e.target.value });
  }

  onChangePhone(e) {
    this.setState({ phone: e.target.value });
  }

  onChangeLocation(e) {
    this.setState({ location: e.target.value });
  }

  onChangeGrade(e) {
    this.setState({ gradeLevel: e.target.value });
  }

  saveProfile() {
    const node = this.formRef.current;
    node.classList.add('was-validated');
    if (!node.checkValidity()) {
      return;
    }
    var data = {
      token: window.sessionStorage.getItem("token"),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      location: this.state.location === "" ? null : Number(this.state.location),
      elementarySchool: (this.state.gradeLevel !== "" && this.state.gradeLevel >= 0 && this.state.gradeLevel < 6),
      middleSchool: (this.state.gradeLevel >= 6 && this.state.gradeLevel < 9),
      highSchool: (this.state.gradeLevel >= 9 && this.state.gradeLevel <= 12),
      advancedSchool: this.state.advancedSchool,
      gradeLevel: this.state.gradeLevel === "" ? null : Number(this.state.gradeLevel),
    };
    AccountDataService.updateProfile(data)
      .then(response => {
        this.setState({ className: "view" });
        var snackbar = document.getElementById("snackbar");
        snackbar.className = "show";
        setTimeout(function () {
          snackbar.className = snackbar.className.replace("show", ""); 
          window.location.reload(); // Workaround for duplicating children glitch
        }, 1000);
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.status);
        console.log(e.response.data.message);
      });
  }

  onClickEdit(e) {
    const node = this.formRef.current;
    node.classList.remove('was-validated');
    this.setState({ className: "edit" });
  }

  render() {
    return (
      <div className="fancy-box">
        <div className={this.state.className}>
          <h1 className="mb-3">Profile {this.state.role && (<span>[{this.state.role}]</span>)}</h1>
          <div className="loading mb-3">
            <p>Loading</p>
          </div>
          <div className="viewedit registration-form">
            {this.state.profileUpdateNeeded && (
              <div className="small badge rounded-pill text-bg-warning mb-3">Please confirm that &quot;Grade Level&quot; and all other details correct and click Save.</div>
            )}
            <form className="row needs-validation" noValidate ref={this.formRef}>
              <div className="col-12 mb-3">
                <button type="button" className="btn btn-secondary view" onClick={this.onClickEdit}>Edit Profile</button>
                <button type="button" className="btn green-button edit" onClick={this.saveProfile}>Save Profile</button>
                <Link to={"/reset-password"}><button className="btn btn-light">Reset Password</button></Link>
              </div>

              {/* Information */}
              <div className="row">

                {/* Left column */}
                <div className="col-12 col-lg-6">

                  {/* Email */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="text" className="form-control" id="email" disabled required value={this.state.email} name="email" aria-describedby="emailHelp" />
                  </div>

                  {/* First name */}
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">First name</label>
                    <input type="text" className="form-control" id="firstName" required value={this.state.firstName} onChange={this.onChangeFirstName} name="firstName" aria-describedby="firstNameHelp" />
                    <div className="invalid-feedback">Please provide your first name</div>
                  </div>

                  {/* Last name */}
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Last name</label>
                    <input type="text" className="form-control" id="lastName" required value={this.state.lastName} onChange={this.onChangeLastName} name="lastName" />
                    <div className="invalid-feedback">Please provide your last name</div>
                  </div>

                  {/* Phone */}
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input type="text" className="form-control" id="phone" required value={this.state.phone} onChange={this.onChangePhone} name="phone" />
                    <div className="invalid-feedback">Please provide your phone number</div>
                  </div>
                </div>

                {/* Right column */}
                <div className="col-12 col-lg-6">

                  {/* Location */}
                  <div className="mb-3">
                    <label className="form-label">Branch</label> <span className="form-text">(<a href="https://www.intellichoice.org/branches/" rel="noreferrer" target="_blank">See locations and schedules</a>)</span>
                    <div className="mb-3">
                      <select className="mb-0 form-select" id="location" required value={this.state.location} onChange={this.onChangeLocation} name="location">
                        <option key="" value="">--- Select --</option>
                        {this.state.locations.map(l => (<option key={l.id} value={l.id}>{l.name}</option>))}
                      </select>
                    </div>
                  </div>

                  {/* Grade */}
                  <div className="mb-3">
                    <label className="form-label">Grade</label>
                    <div className="mb-3">
                      <select className="mb-3 form-select" id="gradeLevel" required value={this.state.gradeLevel} onChange={this.onChangeGrade} name="gradeLevel">
                        <option key="" value="">--- Grade --</option>
                        {this.state.gradeLevels.map((gradeLevel, index) => (<option key={index} value={index}>{gradeLevel}</option>))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div id="snackbar">Profile saved!</div>
      </div>
    );
  }
}

export default withRouter(ProfileStudent);